import classNames from 'classnames'
import styles from './LoadingSpinner.module.css'
import { LoadingSpinnerProps } from './LoadingSpinner.types'

export const LOADER_SIZES = {
  xs: 18,
  sm: 22,
  md: 36,
  lg: 44,
  xl: 58,
}

export const LoadingSpinner = ({
  size = 'md',
  customSize,
  color = 'var(--color-state-info)',
  iconClassName,
}: LoadingSpinnerProps) => {
  const defaultSize = customSize || LOADER_SIZES[size]

  return (
    <span
      role='presentation'
      className={classNames(styles.root, iconClassName)}>
      <svg
        width={`${defaultSize}px`}
        height={`${defaultSize}px`}
        viewBox='0 0 38 38'
        xmlns='http://www.w3.org/2000/svg'
        stroke={color}>
        <g fill='none' fillRule='evenodd'>
          <g transform='translate(2.5 2.5)' strokeWidth='5'>
            <circle strokeOpacity='.5' cx='16' cy='16' r='16' />
            <path d='M32 16c0-9.94-8.06-16-16-16'>
              <animateTransform
                attributeName='transform'
                type='rotate'
                from='0 16 16'
                to='360 16 16'
                dur='1s'
                repeatCount='indefinite'
              />
            </path>
          </g>
        </g>
      </svg>
    </span>
  )
}
